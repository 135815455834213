<template>
  <div id="parkEdit">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/parkLocation' }">园区选址</el-breadcrumb-item>
      <el-breadcrumb-item v-if="isEdit">编辑空间</el-breadcrumb-item>
      <el-breadcrumb-item v-else>发布空间</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 步骤条 -->
    <div class="stepBox" v-if="!isEdit">
      <div class="stepTxt textColor">
        <span>创建空间</span>
        <span class="stepSpot textColor"></span>
      </div>
      <div class="stepConnect textColor"></div>
      <div class="stepTxt textColor">
        <span>填充信息</span>
        <span class="stepSpot textColor"></span>
      </div>
      <div class="stepConnect textColor"></div>
      <div class="stepTxt">
        <span>发布</span>
        <span class="stepSpot"></span>
      </div>
    </div>
    <!-- 表单 -->
    <div class="formBox">
      <el-form :rules="rules" ref="parkForm" :model="parkForm" label-width="120px">
        <!-- 第一行 地址 -->
        <el-row>
          <el-col :span="7">
            <el-form-item label="可注册地址总数" prop="register_adr_count">
              <el-input-number v-model.number ="parkForm.register_adr_count" controls-position="right" :min="1" :max="99999" style="width:100%"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div class="inputText">个</div>
          </el-col>
          <el-col :span="7">
            <el-form-item label="剩余地址数" prop="surplus_adr_count">
              <el-input-number v-model.number ="parkForm.surplus_adr_count" controls-position="right" :min="1" :max="Number(parkForm.register_adr_count)" style="width:100%"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div class="inputText">个</div>
          </el-col>
          <el-col :span="7">
            <el-form-item label="同一地址可注册公司数" prop="adr_compony_count" label-width="180px">
              <el-input-number v-model.number ="parkForm.adr_compony_count" controls-position="right" :min="1" :max="99999" style="width:100%"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div class="inputText">个</div>
          </el-col>
        </el-row>
        <!-- 第二行 电话&二维码-->
        <el-row style="margin-bottom:5px">
          <el-col :span="8">
            <el-form-item label="联系人电话" prop="mobile">
              <el-input v-model="parkForm.mobile" oninput="value=value.replace(/\D/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
              <el-form-item label="联系人微信二维码" label-width="155px">
                <div class="imgBox">
                  <SuperUpload
                    :uploadCardAddress="uploadLogoAddress"
                    :cardplanList="imgList"
                    @success="imgUploadSuccess"
                    @CardPlanRemove="imgRemove"
                    @handleExceed="handleExceed"
                    :size="4194320"
                    width="100%"
                    height="100%"
                  ></SuperUpload>
                </div>
                <p class="tipsImg">建议上传尺寸为1:1的图片</p>
                <p class="tipsImg" style="bottom: -15px;width:250px;">仅支持jpg、jpeg、png格式大小不能大于4M</p>
              </el-form-item>
          </el-col>
        </el-row>
        <!-- 第三行 出租价格-->
        <el-row>
          <el-form-item label="出租价格">
            <div class="reqClass">
              <!-- 线上支付 -->
              <el-row>
                <el-col :span="4">
                  <el-checkbox v-model="Olpay" :label="1" @change="(val)=>payTypeChange(1, val)">线上支付</el-checkbox>
                </el-col>
                <el-col :span="12">
                  <el-row>
                    <el-col :span="11">
                      <el-input-number v-model="parkForm.online_price" :disabled="!Olpay" controls-position="right" :min="0" :precision="2" :max="999999999" style="width:100%"></el-input-number>
                    </el-col>
                    <el-col :span="1">
                      <div  class="inputText">元/</div>
                    </el-col>
                    <el-col :span="11">
                      <el-input-number v-model="parkForm.online_price_length" :disabled="!Olpay" controls-position="right" :min="1" :max="999" style="width:100%"></el-input-number>
                    </el-col>
                    <el-col :span="1">
                      <div  class="inputText">年</div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <!-- 线上支付附选 -->
              <el-form-item v-if="Olpay" label=" "  label-width="50px">
                <el-row style="margin-top: 10px">
                  <el-col :span="4">
                    <el-checkbox v-model="Discount" :label="3" @change="disChange">限时特价</el-checkbox>
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col :span="11">
                        <el-input-number v-model="parkForm.special_price" :disabled="!Discount" controls-position="right" :min="0" :precision="2" :max="999999999" style="width:100%"></el-input-number>
                      </el-col>
                      <el-col :span="1">
                        <div  class="inputText">元/</div>
                      </el-col>
                      <el-col :span="11">
                        <el-input-number v-model="parkForm.special_price_length" :disabled="!Discount" controls-position="right" :min="1" :max="999" style="width:100%"></el-input-number>
                      </el-col>
                      <el-col :span="1">
                        <div  class="inputText">年</div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row style="margin: 10px 0">
                  <el-col :span="16">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="special_date" type="daterange" range-separator="——" start-placeholder="开始日期" end-placeholder="结束日期" :disabled="!Discount">
                    </el-date-picker>
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- 线下支付 -->
              <el-row>
                <el-col :span="4">
                  <el-checkbox v-model="Ofpay" :label="2" @change="(val)=>payTypeChange(2, val)">线下支付</el-checkbox>
                </el-col>
                <el-col :span="12">
                  <el-row>
                    <el-col :span="11">
                      <el-input-number v-model="parkForm.offline_price" :disabled="!Ofpay" controls-position="right" :min="0" :precision="2" :max="999999999" style="width:100%"></el-input-number>
                    </el-col>
                    <el-col :span="1">
                      <div  class="inputText">元/</div>
                    </el-col>
                    <el-col :span="11">
                      <el-input-number v-model="parkForm.offline_price_length" :disabled="!Ofpay" controls-position="right" :min="1" :max="999" style="width:100%"></el-input-number>
                    </el-col>
                    <el-col :span="1">
                      <div  class="inputText">年</div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </el-row>
        <!-- 第四行 相关政策-->
        <el-row>
          <el-form-item label="相关政策">
            <el-select v-model="parkForm.policy_article" multiple filterable remote reserve-keyword placeholder="请输入相关政策名称"
              style="width: 50%;"
              :remote-method="remoteMethod"
              :loading="loading">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <!-- 第五行 类型-->
        <el-row>
          <el-form-item label="类型">
            <el-checkbox-group v-model="parkForm.park_type">
              <el-checkbox v-for="item in selectType" :label="item.id" :key="item.id">{{item.title}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-row>
        <!-- 第六行 行业领域-->
        <el-row>
          <el-form-item label="行业领域" class="organization_industry">
						<el-checkbox-group v-model="industry_type" @change="changeCategory">
							<el-checkbox :label="1">国民经济行业分类</el-checkbox>
							<el-checkbox :label="2">创投行业分类</el-checkbox>
						</el-checkbox-group>
            <el-cascader
              collapse-tags
              v-model="parkForm.policyEconomic_industry"
              :options="options_economic"
							:disabled="disabled_economic"
              :props="{ multiple: true, checkStrictly: true, value:'pkid',label:'title'}"
              :show-all-levels="false"
							style="width: 49%;margin-right: 2%"
            ></el-cascader>
            <el-select
              v-model="parkForm.siteStart_industry"
              multiple
              collapse-tags
							style="width: 49%"
              :disabled="disabled_start"
              placeholder="请选择">
              <el-option
                v-for="item in options_start"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <!-- 第七行 征税统计-->
        <el-row>
          <el-form-item label="征税统计">
            <el-table
              :data="tax"
              height="250"
              border
              style="width: 100%">
              <el-table-column
                align="center"
                prop="tax_name"
                label="税名">
              </el-table-column>
              <el-table-column
                align="center"
                prop="province_proportion"
                width="140"
                label="省级留成">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.province_proportion" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%" @change="taxesChange(scope.row)">
                  </el-input-number>%
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="province_reward"
                width="140"
                label="省级奖励">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.province_reward" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%" @change="taxesChange(scope.row)">
                  </el-input-number>%
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="city_proportion"
                width="140"
                label="地市级留成">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.city_proportion" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%" @change="taxesChange(scope.row)">
                  </el-input-number>%
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="city_reward"
                width="140"
                label="地市级奖励">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.city_reward" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%" @change="taxesChange(scope.row)">
                  </el-input-number>%
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="town_proportion"
                width="140"
                label="县区级留成">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.town_proportion" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%" @change="taxesChange(scope.row)">
                  </el-input-number>%
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="town_reward"
                width="140"
                label="县区级奖励">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.town_reward" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%" @change="taxesChange(scope.row)">
                  </el-input-number>%
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="site_proportion"
                width="140"
                label="园区级留成">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.site_proportion" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%" @change="taxesChange(scope.row)">
                  </el-input-number>%
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="site_reward"
                width="140"
                label="园区级奖励">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.site_reward" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%" @change="taxesChange(scope.row)">
                  </el-input-number>%
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="110"
                label="综合奖励比例">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.comprehensive_proportion" disabled></el-input>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" @click="dialogVisible = true" style="margin-top: 10px">添加</el-button>
          </el-form-item>
        </el-row>
        <!-- 第九行 政策提要-->
        <el-row>
          <el-form-item label="政策提要">
            <el-input type="textarea" v-model="parkForm.policy_summary" maxlength="3000" show-word-limit></el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <el-button style="margin-top: 12px;" @click="nextStep">下一步</el-button>
    <!-- 弹窗 -->
    <el-dialog title="添加征税统计" :visible.sync="dialogVisible" width="80%">
      <div style="display:flex;justify-content: center;">
        <el-form ref="addTaxes" :model="addTaxes" label-width="100px" style="margin:auto">
          <el-form-item label="类型名字">
            <el-input v-model="addTaxes.tax_name" style="width:50%">
            </el-input>
          </el-form-item>
          <div style="display: flex;">
            <el-form-item label="省级留成">
              <el-input-number v-model="addTaxes.province_proportion" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%">
              </el-input-number>%
            </el-form-item>
            <el-form-item label="省级奖励">
              <el-input-number v-model="addTaxes.province_reward" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%">
              </el-input-number>%
            </el-form-item>
          </div>
          <div style="display: flex;">
            <el-form-item label="地市级留成">
              <el-input-number v-model="addTaxes.city_proportion" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%">
              </el-input-number>%
            </el-form-item>
            <el-form-item label="地市级奖励">
              <el-input-number v-model="addTaxes.city_reward" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%">
              </el-input-number>%
            </el-form-item>
          </div>
          <div style="display: flex;">
            <el-form-item label="区县级留成">
              <el-input-number v-model="addTaxes.town_proportion" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%">
              </el-input-number>%
            </el-form-item>
            <el-form-item label="区县级奖励">
              <el-input-number v-model="addTaxes.town_reward" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%">
              </el-input-number>%
            </el-form-item>
          </div>
          <div style="display: flex;">
            <el-form-item label="园区级留成">
              <el-input-number v-model="addTaxes.site_proportion" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%">
              </el-input-number>%
            </el-form-item>
            <el-form-item label="园区级奖励">
              <el-input-number v-model="addTaxes.site_reward" controls-position="right" :min="0" :precision="2" :max="99" style="width:85%">
              </el-input-number>%
            </el-form-item>
          </div>
          <el-form-item label-width="0px" style="display:flex;justify-content: center;">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addTaxesData">确 定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SuperUpload from "@/components/Upload/SuperUpload.vue";
import { success, error, warning } from "@/utils/notification";

export default {
  name: 'parkEdit',
  data() {
    var number2Rul = (rule, value, callback) => {
      if(value>this.parkForm.register_adr_count){
        callback(new Error('剩余地址数不可大于可注册地址数'))
      }
    };
    var phoneRul = (rule, value, callback) => {
      if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))){
        callback(new Error('手机号码错误，请重新填写'))
      }
    };
    return {
      id: '',
      isEdit: false,
      uploadLogoAddress: this.URL.upLoad, // 图片上传地址
      imgList: [], // 二维码图片
      selectType: [], // 类型
      Olpay: false, // 线上支付
      Ofpay: false, // 线下支付
      Discount: false, // 限时特价
      parkForm: {
        organ_id: '',//企业id
        space_id: '', //空间id
        // space_name: '', //空间名
        // image: '', //空间图片
        // province_id: '', //省id
        // city_id: '', //市id
        // town_id: '', //县id
        // address: '', //地址
        register_adr_count: '', // 可注册地址
        surplus_adr_count: '', // 剩余地址
        adr_compony_count: '', // 同一地址可注册
        mobile: '', // 联系人电话
        wechat_image: '', //微信图片
        online_price: '', //线上钱
        online_price_length: '', //线上年
        offline_price: '', //线下钱
        offline_price_length: '', //线下年
        special_price: '', //优惠钱
        special_price_length: '', //优惠年
        special_date_start: '', //优惠日期开始
        special_date_end: '', //	优惠日期结束
        policy_summary: '', // 政策提要
        park_type: [], //园区类型
        siteStart_industry: [],//创投行业
        policyEconomic_industry: [], //国名经济行业
        policy_article: '', //相关政策
      },
      special_date: [], //优惠时间
      loading: false, //远程loading
      options: [], //远程列表
      queryData: [], //请求暂存
      industry_type: [], //行业领域
			options_economic: [], //级联分类领域-国民经济
      options_start: [], //级联分类领域-创投
			disabled_economic: true,
      disabled_start: true,
      tax: [ //征税统计
        {
          id: '', tax_name: '个人所得税',
          province_proportion: 0, province_reward: 0,
          city_proportion: 0, city_reward: 0,
          town_proportion: 0, town_reward: 0,
          site_proportion: 0, site_reward: 0,
          comprehensive_proportion: 0, type: 1,
        },
        {
          id: '', tax_name: '企业所得税',
          province_proportion: 0, province_reward: 0,
          city_proportion: 0, city_reward: 0,
          town_proportion: 0, town_reward: 0,
          site_proportion: 0, site_reward: 0,
          comprehensive_proportion: 0, type: 2,
        },
        {
          id: '', tax_name: '增值税',
          province_proportion:0, province_reward:0,
          city_proportion: 0, city_reward: 0,
          town_proportion: 0, town_reward: 0,
          site_proportion: 0, site_reward: 0,
          comprehensive_proportion: 0, type: 3
        },
      ],
      addTaxes: {
          id: 0, tax_name: '',
          province_proportion: 0, province_reward: 0,
          city_proportion: 0, city_reward: 0,
          town_proportion: 0, town_reward: 0,
          site_proportion: 0, site_reward: 0,
          comprehensive_proportion: 0, type: 4
      },
      dialogVisible: false, //征税添加
      //表单验证
      rules: {
        register_adr_count: [
          { required: true, message: '请输入可注册地址总数', trigger: 'blur', type: 'number'}
        ],
        surplus_adr_count: [
          { required: true, message: '请输入剩余地址总数', trigger: 'blur', type: 'number'},
          // { validator: number2Rul, trigger: ['blur', 'change'], type: 'number'},
        ],
        adr_compony_count: [
          { required: true, message: '请输入同一地址可注册公司数', trigger: 'blur', type: 'number'}
        ],
        mobile: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
          // { validator: phoneRul, trigger: 'blur', type: 'number'},
        ],
      },

    }
  },
  components: { SuperUpload },
  methods: {
    // 获取编辑信息
    getParkData() {
      this.$http.get(this.URL.adminUrl.parkList + '/' + this.id, {
					params: {
						organ_id: sessionStorage.organizationId,
					}
				}).then((res) => {
        let data = res.data.data
        this.parkForm.space_id = data.id //可注册地址总数
        this.parkForm.register_adr_count = data.register_adr_count //可注册地址总数
        this.parkForm.surplus_adr_count = data.surplus_adr_count //剩余地址总数
        this.parkForm.adr_compony_count = data.adr_compony_count //同一地址可注册公司数
        this.parkForm.mobile = data.mobile //联系电话
        let images = [data.wechat_image]; //微信图
        if (images != null) {
          images.map(v => {
            let obj = {};
            obj.uid = "";
            obj.url = v;
            obj.path = v;
            this.imgList.push(obj);
          });
        }
        data.online_price == 0?this.Olpay=false:this.Olpay = true
        data.offline_price == 0?this.Ofpay=false:this.Ofpay = true
        data.special_price == 0?this.Discount=false:this.Discount = true
        this.parkForm.online_price = (Number(data.online_price)/100) //线上支付的价格 单位:分
        this.parkForm.online_price_length = data.online_price_length //线上支付价格的年限
        this.parkForm.offline_price = (Number(data.offline_price)/100) //线下支付的价格 单位:分
        this.parkForm.offline_price_length = data.offline_price_length //线下支付价格的年限
        this.parkForm.special_price = (Number(data.special_price)/100) //优惠价 单位:分
        this.parkForm.special_price_length = data.special_price_length //优惠价年限
        this.special_date = [data.special_date_start, data.special_date_end] //优惠日期

        let list = [],isList = [] //相关政策
        for(let i in data.policyArticle) {
          list.push({'value': data.policyArticle[i].id, 'label': data.policyArticle[i].title})
          isList.push(data.policyArticle[i].id)
        }
        this.loading = false;
        this.options = list
        this.parkForm.policy_article = isList

        let parkId = []// 类型
        for(let i in data.park_types){
          parkId.push(data.park_types[i].id)
        }
        this.parkForm.park_type = parkId

        this.parkForm.policyEconomic_industry = data.siteEconomic_industry //国民经济
        for(let i in data.siteStart_industry){ //创投行业
          this.parkForm.siteStart_industry.push(data.siteStart_industry[i][0])
        }
        let iType = []
        if(data.siteEconomic_industry.length == 0){
          this.disabled_economic = true
        }else{
          this.disabled_economic = false
          iType.push(1)
        }
        if(data.siteStart_industry.length == 0){
          this.disabled_start = true
        }else{
          this.disabled_start = false
          iType.push(2)
        }
        this.industry_type = iType //行业类型
        this.tax = data.tax //税务统计
        this.parkForm.policy_summary = data.policy_summary //政策提要
      })
    },
    // 图片上传成功
    imgUploadSuccess(response, filelist, file) {
      success("图片上传成功");
      if (filelist.length > 0) {
        filelist.map(v => {
          if (v.uid === file.uid) {
            v.path = response.data.path;
          }
        });
      }
      this.imgList = filelist;
    },
    // 图片移除
    imgRemove(filelist) {
      this.imgList = filelist;
    },
    // 图片提示
    handleExceed() {
      error("只能上传1张图片");
    },
    // 远程搜索
    remoteMethod(val) {
      if (val !== '') {
        this.loading = true;
				this.$http.get(this.URL.adminUrl.searchArticle, {
					params: {
						keyword: val,
					}
				}).then((res) => {
          this.loading = false;
          this.queryData = res.data.data
          let list = []
          for(let i in res.data.data) {
            list.push({'value': res.data.data[i].id, 'label': res.data.data[i].title})
          }
          this.loading = false;
          this.options = list
				})
      } else {
        this.options = [];
      }
    },
    // 切换类型
    changeCategory(id) {
      // console.log(id)
			if(id.indexOf(1) == -1){
				this.disabled_economic = true
				this.parkForm.policyEconomic_industry = []
			}else{
				this.disabled_economic = false
			}
			if(id.indexOf(2) == -1){
				this.disabled_start = true
				this.parkForm.siteStart_industry = []
			}else{
				this.disabled_start = false
			}
    },
    //分类 - 创投和国民行业列表
    async getCategories(url) {
      const res = await this.$http.get(this.URL.adminUrl[url], {});
			url == "economics"?this.options_economic = res.data.data:this.options_start = res.data.data
    },
    // 切换支付类型
    payTypeChange(num, val) {
      if(num == 1){ //线上支付
        this.disChange(val)
        if(!val){
          this.parkForm.online_price = ''
          this.parkForm.online_price_length = ''
        }
      }else{ //线下支付
        if(!val){
          this.parkForm.offline_price = ''
          this.parkForm.offline_price_length = ''
        }
      }
      // console.log(num, val)
    },
    // 限时特价
    disChange(val) {
      if(!val){
        this.Discount = val
        this.parkForm.special_price = ''
        this.parkForm.special_price_length = ''
        this.special_date = []
      }
    },
    // 征税统计
    taxesChange(val) {
      let row = val
      row.comprehensive_proportion = Math.round((Number(row.province_reward) * Number(row.province_proportion) + Number(row.city_reward) * Number(row.city_proportion) + Number(row.town_reward) * Number(row.town_proportion) + Number(row.site_reward) * Number(row.site_proportion))*10000)/10000
      for (const i in this.tax) {
        if(this.tax[i].tax_name == val.tax_name){
          this.$set(this.tax, i, row)
        }
      }
    },
    // 增加征税统计
    addTaxesData() {
      this.taxesChange(this.addTaxes)
      let row = JSON.parse(JSON.stringify(this.addTaxes))
      row.comprehensive_proportion = Math.round((Number(row.province_reward) * Number(row.province_proportion) + Number(row.city_reward) * Number(row.city_proportion) + Number(row.town_reward) * Number(row.town_proportion) + Number(row.site_reward) * Number(row.site_proportion))*10000)/10000
      this.$set(this.tax, this.tax.length, row)
      this.dialogVisible = false
    },
    // 下一步
    nextStep() {
      // console.log(this.tax)
      this.$refs['parkForm'].validate((valid) => {
        if (valid) {
          if(this.parkForm.surplus_adr_count>this.parkForm.register_adr_count){
            this.$message.error('剩余地址总数大于同一地址可注册公司数');
            return false
          }
          if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.parkForm.mobile))){
            this.$message.error('电话格式不正确，请重新输入');
            return false
          }
          if(!this.Ofpay && !this.Olpay){
            this.$message.error('请选择出租价格');
            return false
          }else{
            //提交
            this.parkForm.online_price = this.parkForm.online_price*100
            this.parkForm.offline_price = this.parkForm.offline_price*100
            this.parkForm.special_price = this.parkForm.special_price*100
            this.imgList.length>0 && (this.parkForm.wechat_image = this.imgList[0].path) //图片
            this.special_date.length> 0 && (this.parkForm.special_date_start = this.special_date[0]) && (this.parkForm.special_date_end = this.special_date[1]) //时间
            if(this.isEdit){ //编辑提交
              this.$http.post(this.URL.adminUrl.spaceTax + this.parkForm.space_id, {
                tax: this.tax
              }).then((res) => {
                this.$http.post(this.URL.adminUrl.parkList +'/' + this.parkForm.space_id, this.parkForm).then((res) => {
                  this.$message.success('编辑成功')
                  this.$router.push('/parkLocation')
                })
              })
            }else{ //新增提交
              this.parkForm.space_id = this.$route.params.data.space_id	//空间id
              this.parkForm.space_name	= this.$route.params.data.space_name//空间名
              this.parkForm.image = this.$route.params.data.image	//空间图片
              this.parkForm.province_id	 = this.$route.params.data.province_id//省id
              this.parkForm.city_id	= this.$route.params. data.city_id//市id
              this.parkForm.town_id	= this.$route.params.data.district_id//县id
              this.parkForm.address = this.$route.params.data.address	//地址
              this.parkForm.tax = this.tax //税务
              this.$http.post(this.URL.adminUrl.parkList, this.parkForm).then((res) => {
                this.$message.success('提交成功');
                this.$router.push('/parkLocation')
              })
            }
          }
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
  },
  created() {
    this.parkForm.organ_id = sessionStorage.organizationId;
    this.isEdit = this.$route.params.edit
    this.id = this.$route.params.id
    // console.log(this.$route.params.edit)
    this.getCategories("economics");
    this.getCategories("starts");
    this.$http.get(this.URL.spaceTypes).then(r => { //获取类型
      this.selectType = r.data.data
      if(this.isEdit){ //如果是编辑
        this.getParkData()
      }else{ //如果是新建
        this.$route.params.data
      }
    })
  }
}
</script>
<style lang="less" scoped>
  #parkEdit {
    .stepBox {
      width: 550px;
      display: flex;
      justify-content: space-between;
      position: relative;
      margin: 30px 0;
      .stepTxt {
        width: 65px;
        text-align: center;
        .stepSpot {
          margin: 5px auto 0;
          display: block;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: #fff;
          border: 2px solid rgb(187,187,187);
          border-color: rgb(187,187,187);
        }
      }
      .stepConnect{
        position: absolute;
        width: 233px;
        border: 2px solid rgb(187,187,187);
        border-color: rgb(187,187,187);
      }
      &>.stepConnect:nth-child(2) {
        right: 279px;
        top: 27px;
      }
      &>.stepConnect:nth-child(4) {
        right: 37px;
        top: 27px;
      }
      .textColor {
        color: rgb(64,158,255);
        border-color: rgb(64,158,255)!important;
      }
    }
    .formBox {
      margin-top: 30px;
      .inputText {
        line-height: 40px;
        text-align: center;
      }
    }
    .organization_industry{
      .el-radio-group,.el-cascader{
        width: 100%;
      }
    }
    .imgBox {
      position: relative;
      width: 100%;
      height: 160px;
      margin-bottom: 15px;
      overflow: hidden;
      .cardUpload {
        position: absolute;
        top: -35px;
      }
      .imgMask {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
    .tipsImg {
      position: absolute;
      font-size: 12px;
      color: #ed1324;
      width: 170px;
      height: 24px;
      line-height: 24px;
      bottom: 0px;
    }
    .reqClass::before {
      content: '*';
      display: flex;
      color: red;
      position: absolute;
      width: 5px;
      top: 3px;
      left: -80px;
      height: 5px;
    }
  }
</style>
